<template>
  <div class="outter">
    <div class="ad_banner">
      <img src="../assets/image/page2/banner.png" alt="">
    </div>
    <div class="platform" id="part1">
      <div class="title_light">
        <img src="../assets/image/page2/platform.png" alt="">
      </div>
      <div class="main1 clearFix">
        <div class="main1_left">
          <!-- <div @click="change('left')" class="btn btnleft" :class="selectedIndex == 0 ? 'btnleftend':''"><img src="../assets/image/page2/arrowL.png" alt=""></div> -->
          <!-- <div @click="change('right')" class="btn btnright" :class="selectedIndex == 6 ? 'btnrightend':''"><img src="../assets/image/page2/arrowR.png" alt=""></div> -->
          <ul>
            <li v-if="selectedIndex == 0">
              <img src="../assets/image/page2/scroll1.png" alt="">
              <div>
                <h1 class="word-weight">闭环系统/ERP闭环</h1>
                <p class="word-article">药联健康通过与合作连锁的对接，实现了整个消费过程的全闭环。确保商保消费的合规，安全。不同于传统的模式，从流程根本上解决商保的合规安全风险，同时也确保了整个闭环的可控性和高效性。</p>
              </div>
            </li>
            <li v-if='selectedIndex == 1'>
              <img src="../assets/image/page2/scroll2.png" alt="">
              <div>
                <h1 class="word-weight">自动化投保理赔</h1>
                <p class="word-article">药联健康与多家保险行业的龙头公司，如人保、太保、国寿等，完成了系统对接。通过系统自动化的理算、核赔、审核，将整个投保、理赔链路打通，实现每月上亿元的自动化理赔。</p>
              </div>
            </li>
            <li v-if='selectedIndex == 2'>
              <img src="../assets/image/page2/scroll3.png" alt="">
              <div>
                <h1 class="word-weight">风控能⼒/异常消费控制</h1>
                <p class="word-article">药联健康通过风控系统，依赖沉淀的药品大数据能力，智能行为分析，实时监测消费闭环中的异常行为，在消费时就将异常行为拦截。完全颠覆了原有后置核赔，纠纷投诉较多的模式。</p>
              </div>
            </li>
            <li v-if='selectedIndex == 3'>
              <img src="../assets/image/page2/scroll4.png" alt="">
              <div>
                <h1 class="word-weight">多维度精细化消费管控</h1>
                <p class="word-article">药联健康通过用户画像、商品标签，门店评级等系统，进行多维度细化的消费管控。购药前的异常消费拦截，消费中的联合用药推荐，消费后的用药提醒，多角度的专业提示，将更实惠、更快捷、更安全、更有效的服务提供给我们的用户，帮助用户做出更好的购药选择。同时也规避一些异常的消费行为，杜绝一切骗保骗赔的行为。</p>
              </div>
            </li>
            <li v-if='selectedIndex == 4'>
              <img src="../assets/image/page2/scroll5.png" alt="">
              <div>
                <h1 class="word-weight">营销能⼒/社群化运营</h1>
                <p class="word-article">药联通过精准大数据匹配，在病症、药品、保健未病、保障、兴趣、职业等多维度上建立用户间的相互联系，形成社群化社交化专业服务，让用户相互之间找到共鸣和温暖，让药联连接的各个专业服务资源精准服务到社群用户，并形成良性内在循环和外围辐射扩散。</p>
              </div>
            </li>
            <li v-if='selectedIndex == 5'>
              <img src="../assets/image/page2/scroll6.png" alt="">
              <div class="spal_text">
                <h1 class="word-weight">合作伙伴服务平台</h1>
                <p class="word-article"><span class="fontTit">ai智赔系统：</span>AI智赔是药联提供给保险公司客户的自助服务系统，通过智赔系统，保险公司客户可实时查看配置合作的项目、保单，以及保单的投保和理赔状态，让保险公司能及时掌握合作业务状态。</p>
                <p class="word-article"><span class="fontTit">ai智盈系统：</span>Al智盈系统是药联提供给合作连锁管理者的业务管理系统，合作连锁的管理者通过智盈系统可实时查看药联全局业务数据、合作连锁自身的业务数据，并通过系统建议优化合作。</p>
              </div>
            </li>
            <li v-if='selectedIndex == 6'>
              <img src="../assets/image/page2/scroll7.png" alt="">
              <div class="spal_text">
                <h1 class="word-weight">自主安全</h1>
                <p class="word-article">药联依托强大的研发以及技术能力，保障药联健康服务的安全、高效、稳定、领先。公司在上海、南京、北京分设3个技术中心，上百人的产品、技术研发团队，数十条产品线持续迭代，保持系统可用性99. 99%以上，支撑5万QPS的请求。</p>
              </div>
            </li>
          </ul>
        </div>
        <div @scroll="scrollTit" class="main1_right" ref="txtScroll">
          <ul class="ulScroll">
            <li @click="change(0)" :class="selectedIndex == 0 ? 'on':''" data-index='0'>
              闭环系统/ERP闭环
              <span></span>
            </li>
            <li @click="change(1)" :class="selectedIndex == 1 ? 'on':''" data-index='1'>自动化投保理赔<span></span></li>
            <li @click="change(2)" :class="selectedIndex == 2 ? 'on':''" data-index='2'>风控能⼒/异常消费控制<span></span></li>
            <li @click="change(3)" :class="selectedIndex == 3 ? 'on':''" data-index='3'>多维度精细化消费管控<span></span></li>
            <li @click="change(4)" :class="selectedIndex == 4 ? 'on':''" data-index='4'>营销能⼒/社群化运营<span></span></li>
            <li @click="change(5)" :class="selectedIndex == 5 ? 'on':''" data-index='5'>合作伙伴服务平台化<span></span></li>
            <li @click="change(6)" :class="selectedIndex == 6 ? 'on':''" data-index='6'>自主安全<span></span></li>
          </ul>
          <div class="ability-scrollbar">
            <div class="ability-scrollbar-scroll" :style="{ 'top': scrollBarTop }"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="network" id="part2" style="display: block">
      <div class="container">
        <div class="title_light">
          <img src="../assets/image/page2/network.png" alt="">
        </div>
        <div class="network-content clearFix">
          <ul class="network-left">
            <li class="word-weight">遍布全国的药店网络及到家配送服务</li>
            <li class="word-article">药联健康已接入连锁药店10万余家，2020年年底预计预测突破15万家门店。占全国连锁药店的29%，持有药联商保出单APP的注册店员约30万人，药品平均月交易订单量84.9万单。依托完善的药店覆盖，药联O2O到家服务已陆续在各大城市上线，提供更好更快的购药服务。</li>
            <li class="word-weight">丰富的诊疗及服务</li>
            <li class="word-article">截至2020年年底，积累了3000+的医疗资源和5年以上服务经验，服务于多家优质企业和终端消费者，在行业内拥有着良好的口碑和服务能力</li>
            <li class="word-weight">药联健康服务核心竞争力</li>
            <li class="word-article">
              1. 强大的医疗资源：我们拥有1084+的三甲医院医疗资源, 覆盖全国100+的城市。<br/>
              2. 完善的服务团队：我们拥有5年以上经验丰富、专业的客服中心和客服团队、保障客户优质体验。<br/>
              3. 丰富的服务经验：我们与多家保险、银行等企业合作，服务终端消费者累计百万。<br/></li>
          </ul>
          <div class="network-right">
            <img src="../assets/image/page2/map.png" alt="">
          </div>
          <div class="network-bg"></div>
        </div>
      </div>
    </div>
    <div id='part3' class="drive">
      <div class="container">
        <div class="title_light">
          <img src="../assets/image/page2/drive.png" alt="">
        </div>
        <div class="main3_in clearFix">
          <div class="fl">
            <div class="pic-wrap"><img src="../assets/image/page2/icon1.png" alt=""></div>
            <h1>用户画像</h1>
            <p>药联用户大数据，根据地区、年龄、性别、职业、车主、高血压、心脑血管疾病、糖尿病、各种病症、购药习惯、保健习惯、需求健康知识、兴趣爱好、社群群体、活动喜好、身体健康数据、病症周期、服药周期、基因检测数据、咨询交互数据等等多维度交叉矩阵属性，在最细的颗粒度上构建用户画像，精准匹配业务场景和服务。</p>
            <div class="ft-line"></div>
          </div>
          <div class="fl">
            <div class="pic-wrap"><img src="../assets/image/page2/icon2.png" alt=""></div>
            <h1>精准化持续服务/购药后服务</h1>
            <p>· 药品电子说明书-想看就看，不再找不到。<br />· 用药提醒-及时提醒按时用药，避免同服相克药品。“每天3次”的有效触达。<br />· 健康咨询-了解病症的相关知识，不用久病就能成良医。“每种病症3000篇知识素材”。</p>
            <div class="ft-line"></div>
          </div>
          <div class="fl">
            <div class="pic-wrap"><img src="../assets/image/page2/icon3.png" alt=""></div>
            <h1>友好性转化</h1>
            <p>药联树立和严格遵循让老百姓用药“更实惠、更快捷、更安全、更有效”的宗旨，坚持先服务后转化的原则，通过口碑传播转化、药店场景转化、精准大数据匹配需求转化、红包活动转化、增值服务保障转化、精准匹配相关知识转化、社群温暖服务转化等多种友好性转化方式，将药联的服务和产品带给用户，匹配用户需求、全周期服务好用户。</p>
            <div class="ft-line"></div>
          </div>
          <div class="fl">
            <div class="pic-wrap"><img src="../assets/image/page2/icon4.png" alt=""></div>
            <h1>社交化转播</h1>
            <p>以精准大数据匹配和社群裂变、分销裂变、口碑传播裂变、红包活动裂变等多维度方式组成药联用户的社交化传播方式，推动药联业务和用户的指数级增长。</p>
            <div class="ft-line"></div>
          </div>
        </div>
      </div>
    </div>
    <div id='part4' class="main4_out">
      <div class="title_light">
        <img src="../assets/image/page2/team.png" alt="">
      </div>
      <div class="main4 clearFix">
        <div class="fl">
          <img src="../assets/image/page2/team1.png" alt="">
          <p class="word-weight">连锁药店团队</p>
          <div class="word-article">药联在全国各个区域省份均有专职商务总监负责服务对接合作连锁药店，结合不同的连锁客户自身特点，提供针对性的专属服务方案，通过药联商保直付以及增值服务业务帮助连锁药店创造业绩增量。</div>
        </div>
        <div class="fl">
          <img src="../assets/image/page2/team2.png" alt="">
          <p class="word-weight">保险团队 </p>
          <div class="word-article">目前药联保险业务团队在全国各区域均配置有专职商务总监，负责和保险公司伙伴的合作沟通为保险公司在合作的前、中、后提供全面细致的服务支持。</div>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 272px"></div>
  </div>
</template>
<script>
export default {
  name: 'page2',
  data() {
    return {
      selectedIndex: 0,
      leftEnd: false,
      rightEnd: false
    }
  },
  computed: {
    scrollBarTop() {
      return this.selectedIndex * (110 + 20) + 'px';
    }
  },
  created() {},
  mounted() {
    console.log(this.$refs.ulScroll)
    if (this.$route.hash) {
      document.querySelector(this.$route.hash).scrollIntoView(true)
    }
  },
  methods: {
    change(index) {
      let selectedIndex = this.selectedIndex
      if (index == 'left') {
        if (selectedIndex == '0') {
          this.leftEnd = true
        } else {
          selectedIndex--
          this.selectedIndex = selectedIndex
          this.leftEnd = false
          this.rightEnd = false
        }
      } else if (index == 'right') {
        if (selectedIndex == '6') {
          this.rightEnd = true
        } else {
          selectedIndex++
          this.selectedIndex = selectedIndex
          this.leftEnd = false
          this.rightEnd = false
        }
      } else {
        this.selectedIndex = index
      }
    },
    scrollTit() {
      console.log(this.$refs.txtScroll.scrollTop)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../assets/css/mixin.less';
.platform {
  padding: 70px 0 0;

  .title_light img {
    .wh(602px, 64px);
  }
}

.network {
  background-color: #f9f9f9;
  padding: 55px 0 32px;
  overflow: hidden;
  
  .title_light img {
    .wh(528px, 64px);
    position: relative;
    z-index: 20;
  }

  .container {
    position: relative;
  }

  &-content {
    padding: 40px 0 0;
    overflow: hidden;
  }

  &-bg {
    background: url('../assets/image/page2/network-bg.png') no-repeat;
    .wh(1053px, 656px);
    position: absolute;
    right: -215px;
    top: -55px;
    z-index: 10;
  }

  &-left {
    width: 625px;
    float: left;
    position: relative;
    z-index: 20;

    li {
      &:nth-of-type(2n-1) {
        padding: 0 0 21px;
      }
      &:nth-of-type(2n) {
        padding: 0 0 40px;
        line-height: 26px;
      }
    }
  }

  &-right {
    float: left;
    padding: 47px 0 0 41px;
    
    img {
      position: relative;
      z-index: 20;
      .wh(473px, 408px);
    }
  }
}

.drive {
  padding: 73px 0 0;

  .title_light img {
    .wh(467px, 64px);
  }
}

.main1 {
  width: 1200px;
  margin: auto;
  position: relative;

  .main1_left {
    position: relative;
    width: 670px;
    height: 486px;
    float: left;
    margin: 54px 0;
    .btn {
      width: 40px;
      height: 56px;
      position: absolute;
      top: 50%;
      transform: translate(0, -28px);
      background: #00cdb7;
      cursor: pointer;
      z-index: 2;
      display: flex;
      align-items: center;
      img {
        width: 13px;
        height: 24px;
        margin: auto;
      }
    }
    .btnleft {
      left: 0;
    }
    .btnright {
      right: 0;
    }
    .btnleftend {
      background: rgba(255, 255, 255, 0.3);
    }
    .btnrightend {
      background: rgba(255, 255, 255, 0.3);
    }
    ul {
      .wh(670px, auto);

      li {
        > img {
          width: 100%;
          height: auto;
        }
        > div {
          // padding: 0 90px;
          box-sizing: border-box;
          h1 {
            padding: 44px 0 16px;
          }
          .line {
            width: 95px;
            height: 2px;
            background: rgba(0, 205, 183, 1);
            border-radius: 2px;
            margin: 16px 0 30px 0;
          }
          p {
            // color: #fff;
            // font-size: 14px;
            line-height: 26px;
            text-align: justify;
            
            span.fontTit {
              color: #333;
            }
          }
        }
      }
    }
  }
  .main1_right {
    float: left;
    .wh(466px, 500px);
    overflow: auto;
    padding: 4px 0;
    box-sizing: border-box;
    margin: 54px 0 54px 17px;

    &::-webkit-scrollbar{
        display: none;
    }

    ul {
      width: 435px;
      float: left;
      height: 100%;

      li {
        width: 435px;
        height: 110px;
        line-height: 110px;
        background: #FFFFFF;
        border-radius: 20px;
        box-sizing: border-box;
        margin: 0 0 20px;
        text-align: right;
        cursor: pointer;
        .font(20px, #666);
        transform: .2s all linear;
        padding-right: 90px;
        position: relative;

        &:hover {
          box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.06);
          position: relative;
          color: #00B095;
        }

        >span {
          .wh(50px, 50px);
          display: block;
          background-size: cover;
          background-repeat: no-repeat;
          position: absolute;
          right: 20px;
          top: 30px;
        }

        &:nth-of-type(1) {
          > span {
            background-image: url('../assets/image/page2/ico-1.png');
          }
        }

        &:nth-of-type(2) {
          > span {
            background-image: url('../assets/image/page2/ico-2.png');
          }
        }

        &:nth-of-type(3) {
          > span {
            background-image: url('../assets/image/page2/ico-3.png');
          }
        }

        &:nth-of-type(4) {
          > span {
            background-image: url('../assets/image/page2/ico-4.png');
          }
        }

        &:nth-of-type(5) {
          > span {
            background-image: url('../assets/image/page2/ico-5.png');
          }
        }

        &:nth-of-type(6) {
          > span {
            background-image: url('../assets/image/page2/ico-6.png');
          }
        }

        &:nth-of-type(7) {
          > span {
            background-image: url('../assets/image/page2/ico-7.png');
          }
        }
      }
      li.on {
        box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.06);
        position: relative;
        color: #00B095;

        &:nth-of-type(1) {
          > span {
            background-image: url('../assets/image/page2/ico-1-1.png');
          }
        }

        &:nth-of-type(2) {
          > span {
            background-image: url('../assets/image/page2/ico-2-1.png');
          }
        }

        &:nth-of-type(3) {
          > span {
            background-image: url('../assets/image/page2/ico-3-1.png');
          }
        }

        &:nth-of-type(4) {
          > span {
            background-image: url('../assets/image/page2/ico-4-1.png');
          }
        }

        &:nth-of-type(5) {
          > span {
            background-image: url('../assets/image/page2/ico-5-1.png');
          }
        }

        &:nth-of-type(6) {
          > span {
            background-image: url('../assets/image/page2/ico-6-1.png');
          }
        }

        &:nth-of-type(7) {
          > span {
            background-image: url('../assets/image/page2/ico-7-1.png');
          }
        }
      }
    }
  }

  .ability-scrollbar{
    .wh(3px, 886px);
    margin: 0 0 0 28px;
    background-color: rgba(216, 216, 216, 1);
    position: relative;
    float: left;

    &-scroll {
      transition: .2s all linear;
      .wh(3px, 110px);
      position: absolute;
      left: 0;
      top:0;
      background-color: rgba(0, 176, 149, 1);
    }
  }
}
.main3_in {
  padding-top: 40px;
  margin-bottom: 73px;
  .fl {
    width: 278px;
    margin-right: 29px;
    height: 440px;
    box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    transition: .2s all linear;
    cursor: pointer;

    &:hover{
      transform: scale(1.02);
      box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.06);
      border-radius: 22px;
      .ft-line {
        opacity: 1;
      }
    }

    .pic-wrap {
      width: 72px;
      height: 72px;
      background: #fff;
      border-radius: 50%;
      margin: 40px auto 22px;

      img {
        .wh(100%, 100%);
        margin: auto;
      }
    }
    h1 {
      color: #2a2a2a;
      font-size: 17px;
      text-align: center;
    }
    p {
      .font(14px, #777);
      line-height: 24px;
      text-align: justify;
      padding: 16px 18px 0 23px;
    }
    .ft-line {
      .wh(100%, 8px);
      position: absolute;
      bottom: 0;
      left:0;
      background-color: rgb(0, 176, 149);
      opacity: .1;
    }
  }
  .fl:nth-child(4n) {
    margin-right: 0;
  }
}
.main4_out {
  background-color: rgba(235, 249, 247, 1);
  .wh(100%, 429px);
  padding: 60px 0 0;
  box-sizing: border-box;

  .title_light img {
    .wh(525px, 66px);
  }

  .main4 {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0 0;

    .fl {
      width: 588px;
      height: 465px;
      margin-right: 20px;
      box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      box-sizing: border-box;

      &:last-child {
        margin-right: 0;
      }

      img {
        .wh(588px, 272px);
      }
      p {
        padding: 30px 0 0 30px;
      }

      .word-article {
        padding: 16px 37px 0 31px;
        line-height: 26px;
      }
    }
  }
}
</style>